import * as React from "react";
import * as Yup from "yup";
import { ref } from "yup";
import { useFormik, Form, FormikProvider, Formik } from "formik";
import { PersistFormikValues } from "formik-persist-values";
import Container from "@mui/material/Container";
import { CircularProgress } from "@mui/material";
import { jsonConcat } from "./../helper/helper";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  Button,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Step4 = ({ activeStep, handleBack, handleNext, steps, handleSubmitForm, submitError, setSubmitError }) => {
  // password
  const [values, setValues] = React.useState({
    password: "",
    confirmPassword: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // remove validation for username on keyup
  React.useEffect(() => {
    const usernameInput = document.querySelector('input[name="login_name"]');

    usernameInput.addEventListener("keyup", (e) => {
      setSubmitError(false);
      setSubmitting(false);
    });
  }, []);

  // validate
  const Step4Schema = Yup.object().shape({
    login_name: Yup.string().required("This field is required"),
    password: Yup.string()
      .required("This field is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{7,})/,
        "Must Contain At Least 7 Characters, One Uppercase, One Lowercase, and One Number"
      ),
    confirmPassword: Yup.string()
      .required("This field is required")
      .oneOf([ref("password")], "Passwords do not match"),
  });

  const formik = useFormik({
    initialValues: {
      login_name: "",
      password: "",
      confirmPassword: "",
      comments: "",
    },
    validationSchema: Step4Schema,
  });

  const { errors, touched, handleSubmit, getFieldProps, validateForm, isSubmitting, setSubmitting } = formik;

  return (
    <Container disableGutters>
      <FormikProvider value={formik} enableReinitialize>
        <Box
          //component="form"
          noValidate
          autoComplete="off"
          alignItems="center"
          justifyContent="center"
          id="step-4-page"
        >
          <TextField
            fullWidth
            required
            label="Choose a Username"
            name="login_name"
            variant="outlined"
            align="center"
            id="username-field"
            {...getFieldProps("login_name")}
            error={
              Boolean(touched.login_name && errors.login_name) || submitError
            }
          />
          {(submitError || (!!errors.login_name && !!errors.login_name)) && (
            <FormHelperText error>
              {submitError && <span>Username is taken.</span>}
              {!!errors.login_name && !!errors.login_name && (
                <span>{errors.login_name}</span>
              )}
            </FormHelperText>
          )}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
            id="password-section"
          >
            <FormControl sx={{ width: "49%" }} variant="outlined">
              <InputLabel htmlFor="password">Password *</InputLabel>
              <OutlinedInput
                required
                id="password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                // onChange={handleChange("password")}
                inputProps={{ maxLength: 40 }}
                {...getFieldProps("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                name="password"
                error={Boolean(touched.password && errors.password)}
              />
              {!!touched.password && !!errors.password && (
                <FormHelperText error>{errors.password}</FormHelperText>
              )}
            </FormControl>
            <FormControl sx={{ width: "49%" }} variant="outlined">
              <InputLabel htmlFor="confirmPassword">
                Re-enter Password *
              </InputLabel>
              <OutlinedInput
                required
                id="confirmPassword"
                type={values.showPassword ? "text" : "password"}
                {...getFieldProps("confirmPassword")}
                // value={values.confirmPassword}
                // onChange={handleChange("passwordConfirmation")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                // label="Re-enter Password"
                name="confirmPassword"
                error={Boolean(
                  touched.confirmPassword && errors.confirmPassword
                )}
              />
              {!!touched.confirmPassword && !!errors.confirmPassword && (
                <FormHelperText error>{errors.confirmPassword}</FormHelperText>
              )}
            </FormControl>
          </Box>
          <TextField
            fullWidth
            id="comments"
            name="comments"
            label="Anything else you'd like to add?"
            multiline
            rows={4}
            value={formik.initialValues.comments ?? ""}
            {...getFieldProps("comments")}
          />
        </Box>
        <div className="navigate">
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1, p: "1em 3em" }}
              variant="contained"
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              onClick={() => {
                console.log(submitError);
                if (submitError) {
                  setSubmitting(false);
                } else {
                  handleSubmit();
                }

                validateForm().then((errors) => {
                  console.log(errors);
                  const isEmpty = (obj) => {
                    for (let key in obj) {
                      if (obj.hasOwnProperty(key)) return false;
                    }
                    return true;
                  };

                  const pass = isEmpty(errors);
                  if (pass === false) {
                    console.log(errors);
                  } else {
                    // parse local storage string into array
                    const formValuesArray = JSON.parse(
                      localStorage.getItem("formValues")
                    );

                    // turn formik values into array
                    const values = formik.values;
                    const formikValues = JSON.stringify(values);
                    let backToArray = JSON.parse(formikValues);

                    let output = {};
                    output = jsonConcat(output, formValuesArray);
                    output = jsonConcat(output, backToArray);

                    // set new array in local storage
                    localStorage.setItem("formValues", JSON.stringify(output));

                    handleSubmitForm(output);
                  }
                });
              }}
              variant="contained"
              sx={{ mr: 1, p: "1em 3em" }}
              type={activeStep === steps.length - 1 ? "submit" : "button"}
            >
              {isSubmitting ? <CircularProgress color="secondary" /> : "Submit"}
            </Button>
          </Box>
        </div>
        <PersistFormikValues name="signup-form-step-4" />
      </FormikProvider>
    </Container>
  );
};

export default Step4;
