import React from 'react';
import ReactDOM from 'react-dom'
import App from './App'
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from "@mui/material/";
import { theme } from "./theme";
//import './index.scss'

ReactDOM.render(
  <React.Fragment>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.Fragment>,
  document.getElementById("app")
);

