import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Container from '@mui/material/Container';
import Home from "./views/Home";
import RegisterConfirmation from "./views/RegisterConfirmation";
import SignUpForm from "./views/SignUpForm";
import ThankYou from "./views/ThankYou";
import Error from "./views/Error";
import './app.scss';

const App = React.memo(() => {
  return (
    <Container disableGutters>
      <BrowserRouter>
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/confirm" element={<RegisterConfirmation />} />
            <Route path="/signup" element={<SignUpForm />} />
            <Route path="/thankyou" element={<ThankYou />} />
            <Route path="/error" element={<Error />} />
          </Routes>
        </div>
      </BrowserRouter>
    </Container>
  );
});

export default App;
