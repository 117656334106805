import React from "react";
import Container from "@mui/material/Container";
import { Box } from "@mui/material";
import mainLogo from "../assets/images/DMS-PAM-logo.png";
import "../app.scss";

const RegisterConfirmation = () => {
  return (
    <Container disableGutters>
      <Box sx={{ maxWidth: "700px", m: "4em auto 1em" }}>
        <img src={mainLogo} className="logo" alt="DMS Performance Ad Market" />
        <h1>
          Please check your email and click on the link we sent you to continue.
        </h1>

        <h3>
          Didn't get the email? Please check your junk folder. Don't forget to
          add dmsgroup.com to your whitelist so you never miss an important
          email.
        </h3>
      </Box>
    </Container>
  );
};

export default RegisterConfirmation;
