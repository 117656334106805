import React, { useState } from "react";
import * as Yup from "yup";
import { ref } from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Stack, TextField, Button, CircularProgress, FormGroup, Checkbox, FormControlLabel, FormHelperText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// ----------------------------------------------------------------------

export default function Register() {
  let navigate = useNavigate();

  const RegisterSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First name required"),
    last_name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Last name required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    confirmEmail: Yup.string()
      .oneOf([ref("email")], "Emails do not match")
      .required("Please confirm your email"),
    agree: Yup.bool()
      .oneOf([true], "You must agree to the terms and conditions.")
      .required("You must agree to the terms and conditions."),
  });

  // const api_key = process.env.REACT_APP_API_URL_EMAIL;
  const api_key = "https://cliql.dmsgroup.com/publisher/signup/email";
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      confirmEmail: "",
      agree: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      axios({
        method: "post",
        url: api_key,
        crossDomain: true,
        data: {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          url: window.location.href,
        },
      }).then(function (response) {
        navigate("/confirm");
      });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, isSubmitting } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              name="first_name"
              label="First Name"
              {...getFieldProps("first_name")}
              error={Boolean(touched.first_name && errors.first_name)}
              helperText={touched.first_name && errors.first_name}
            />

            <TextField
              fullWidth
              label="Last Name"
              name="last_name"
              {...getFieldProps("last_name")}
              error={Boolean(touched.last_name && errors.last_name)}
              helperText={touched.last_name && errors.last_name}
            />
          </Stack>

          <TextField
            fullWidth
            autoComplete="email"
            type="email"
            name="email"
            label="Email Address"
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="email"
            type="email"
            name="confirmEmail"
            label="Confirm Email Address"
            {...getFieldProps("confirmEmail")}
            error={Boolean(touched.confirmEmail && errors.confirmEmail)}
            helperText={touched.confirmEmail && errors.confirmEmail}
          />

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  required={true}
                />
              }
              name="agree"
              {...getFieldProps("agree")}
              label={
                <div>
                  <span>I agree to </span>
                  <a href="https://network.dmsgroup.com/terms.php" target="_blank">
                    terms and conditions.
                  </a>
                </div>
              }
            />
          </FormGroup>
          <FormHelperText sx={{ marginTop: "0px !important" }}>{touched.agree && errors.agree}</FormHelperText>

          <Button size="large" type="submit" variant="contained">
            {isSubmitting ? <CircularProgress color="secondary" /> : "Submit"}
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
