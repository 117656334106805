import * as React from "react";
import * as Yup from "yup";
import { ref } from "yup";
import { useFormik, Form, FormikProvider, Formik } from "formik";
import { PersistFormikValues } from "formik-persist-values";
import Container from "@mui/material/Container";
import { jsonConcat } from "./../helper/helper";
import {
  Box,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
  FormHelperText,
} from "@mui/material";

const Step3 = ({channels, activeStep, handleBack, handleNext, steps}) => {
  const [trafficMethod, setTrafficMethod] = React.useState();
  const [earnings, setEarnings] = React.useState([]);
  const [useOtherCpaNetworks, setUseOtherCpaNetworks] = React.useState("");


  const handleChangeEarnings = (event) => {
    const {
      target: { value },
    } = event;
    setEarnings(typeof value === "string" ? value.split(",") : value);
  };

  const handleUseOtherCpaNetwork = (event) => {
    const {
      target: { value },
    } = event;

    setUseOtherCpaNetworks(typeof value === "string" ? value.split(",") : value);
    setFieldValue("use_other_cpa_networks", value);
  };

  // validate
  const Step3Schema = Yup.object().shape({
    how_do_you_market: Yup.string().required("This field is required"),
    monthly_traffic_income: Yup.string().required("This field is required"),
    use_other_cpa_networks: Yup.string().required("This field is required"),
  });

  const formik = useFormik({
    initialValues: {
      how_do_you_market: "",
      monthly_traffic_income: "",
      other_channels: [],
      use_other_cpa_networks: "",
      other_cpa_networks: "",
    },
    validationSchema: Step3Schema,
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    validateForm,
    setFieldValue,
  } = formik;

  return (
    <Container disableGutters>
      <FormikProvider value={formik} enableReinitialize>
        <div>
          <Box
            //component="form"
            autoComplete="off"
            alignItems="center"
            justifyContent="center"
          >
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="traffic-method-label">
                What is your primary method of driving traffic? *
              </InputLabel>
              <Select
                required
                labelId="traffic-method-label"
                id="traffic-method"
                name="how_do_you_market"
                value={trafficMethod}
                {...getFieldProps("how_do_you_market")}
                error={Boolean(
                  touched.how_do_you_market && errors.how_do_you_market
                )}
              >
                {channels
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>
                {touched.how_do_you_market && errors.how_do_you_market}
              </FormHelperText>
            </FormControl>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="other-channels-label">
                Are there other channels you use to drive traffic?
              </InputLabel>
              <Select
                multiple
                labelId="other-channels-label"
                id="other_channels"
                name="other_channels"
                {...getFieldProps("other_channels")}
                label="Are there other channels you use to drive traffic?"
              >
                {channels
                  .filter((item) => item.id !== formik.values.how_do_you_market)
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Additional insight about your traffic that you would like to share"
              name="traffic_notes"
              variant="outlined"
              align="center"
              multiline
              rows={4}
              {...getFieldProps("traffic_notes")}
            />
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="earnings-label">
                How much do you earn per month in Affiliate Marketing? *
              </InputLabel>
              <Select
                required
                labelId="earnings-label"
                id="earnings"
                name="monthly_traffic_income"
                value={earnings}
                label="How much do you earn per month in Affiliate Marketing? *"
                onChange={handleChangeEarnings}
                {...getFieldProps("monthly_traffic_income")}
                error={Boolean(
                  touched.monthly_traffic_income &&
                    errors.monthly_traffic_income
                )}
              >
                <MenuItem value="$0 - $4,999">$0 - $4,999</MenuItem>
                <MenuItem value="$5,000 - $9,999">$5,000 - $9,999</MenuItem>
                <MenuItem value="$10,000 - $24,999">$10,000 - $24,999</MenuItem>
                <MenuItem value="$25,000 - $49,999">$25,000 - $49,999</MenuItem>
                <MenuItem value="$50,000 - $99,999">$50,000 - $99,999</MenuItem>
                <MenuItem value="$100,000 +">$100,000 +</MenuItem>
              </Select>
              <FormHelperText>
                {touched.monthly_traffic_income &&
                  errors.monthly_traffic_income}
              </FormHelperText>
            </FormControl>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="other-cpa-label">
                Do you work with other CPA networks? *
              </InputLabel>
              <Select
                required
                labelId="other-cpa-label"
                id="use_other_cpa_networks"
                value={useOtherCpaNetworks || ""}
                name="use_other_cpa_networks"
                label="Do you work with other CPA networks? *"
                onChange={handleUseOtherCpaNetwork}
                {...getFieldProps("use_other_cpa_networks")}
                error={Boolean(
                  touched.use_other_cpa_networks &&
                    errors.use_other_cpa_networks
                )}
              >
                <MenuItem value="1">Yes</MenuItem>
                <MenuItem value="0">No</MenuItem>
              </Select>
              <p id="cpa-required">This field is required</p>
              <FormHelperText>
                {touched.use_other_cpa_networks &&
                  errors.use_other_cpa_networks}
              </FormHelperText>
            </FormControl>
            {formik.values.use_other_cpa_networks &&
            formik.values.use_other_cpa_networks == "1" ? (
              <TextField
                fullWidth
                name="other_cpa_networks"
                label="Which CPA networks?"
                {...getFieldProps("other_cpa_networks")}
                variant="outlined"
                align="center"
                inputProps={{ maxLength: 255 }}
              />
            ) : null}
          </Box>
          <div className="navigate">
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1, p: "1em 3em" }}
                variant="contained"
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                onClick={() => {
                  // handleSubmit function is to force validation- it will give an error in the console but ignore it
                  handleSubmit();
                  validateForm().then((errors) => {
                    const isEmpty = (obj) => {
                      for (let key in obj) {
                        if (obj.hasOwnProperty(key)) return false;
                      }
                      return true;
                    };

                    const pass = isEmpty(errors);
                    if (pass === false) {
                      console.log(errors);
                    } else {
                      // parse local storage string into array
                      const formValuesArray = JSON.parse(
                        localStorage.getItem("formValues")
                      );

                      // turn formik values into array
                      const values = formik.values;
                      const formikValues = JSON.stringify(values);
                      let backToArray = JSON.parse(formikValues);

                      let output = {};
                      output = jsonConcat(output, formValuesArray);
                      output = jsonConcat(output, backToArray);

                      // set new array in local storage
                      localStorage.setItem(
                        "formValues",
                        JSON.stringify(output)
                      );

                      // validate use_other_cpa_networks manually because they are not mui
                      if (use_other_cpa_networks === "") {
                        document.getElementById("cpa-required").style.display =
                          "block";
                        document
                          .getElementById("use_other_cpa_networks")
                          .classList.add("error");
                      } else {
                        // next page
                        handleNext();
                      }
                    }
                  });
                }}
                variant="contained"
                sx={{ mr: 1, p: "1em 3em" }}
                type={activeStep === steps.length - 1 ? "submit" : "button"}
              >
                {activeStep === steps.length - 1 ? "Submit" : "Next"}
              </Button>
            </Box>
          </div>
        </div>
        <PersistFormikValues name="signup-form-step-3" />
      </FormikProvider>
    </Container>
  );
};

export default Step3;
