import * as React from "react";
import * as Yup from "yup";
import { ref } from "yup";
import { useFormik, Form, FormikProvider, useFormikContext } from "formik";
import axios from "axios";
import { Box, Stepper, Step, StepLabel, Button } from "@mui/material";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import mainLogo from "../assets/images/DMS-PAM-logo.png";
import { CSSTransition } from "react-transition-group";
import { useNavigate } from "react-router-dom";
import { jsonConcat } from "./helper/helper";
import "../app.scss";

const SignUpForm = React.memo(() => {
  let navigate = useNavigate();

  // response
  const [response, setResponse] = React.useState(false);
  const [submitError, setSubmitError] = React.useState(false);

  const [activeStep, setActiveStep] = React.useState(0);
  const [isSelected, setIsSelected] = React.useState(false);

  // fetch channels
  const [userData, setUserData] = React.useState([]);
  const [channels, setChannels] = React.useState(false);

  const ref = React.useRef(null);

  // get token from url
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  // load initial data
  // const api_load_key = process.env.REACT_APP_API_URL_LOAD;
  const apiLoadUrl =
    process.env.NODE_ENV === "production"
      ? "https://cliql.dmsgroup.com/publisher/signup/load"
      : "https://cliql.dmsgroup.com/publisher/signup/load";
  React.useEffect(() => {
    axios({
      method: "post",
      headers: { "X-Requested-With": "XMLHttpRequest" },
      url: apiLoadUrl,
      data: {
        token: token,
      },
    }).catch(function (error) {
      if (error) {
        setResponse(error);
        navigate("/error");
      }
    }).then(function (response) {
      if (response) {
        if (response.errors) {
          navigate("/error");
        } else {
          setResponse(true);
          setChannels(response.data.data.channels);
          setUserData(response.data.data.publisher_signup);
        }
      }
    });
  }, []);

  // define steps names and get step content
  function getSteps() {
    return [
      "Company Info",
      "Contact Details",
      "Traffic Channels",
      "Create Login",
    ];
  }

  const steps = getSteps();

  const handleNext = (e) => {
    setIsSelected(!isSelected);

    if (activeStep === steps.length - 1) {
      e.preventDefault();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Step1 activeStep={activeStep} handleBack={handleBack} handleNext={handleNext} steps={steps}/>;
      case 1:
        return (
          <Step2
            activeStep={activeStep}
            handleBack={handleBack}
            handleNext={handleNext}
            steps={steps}
          />
        );
      case 2:
        return (
          <Step3
            channels={channels}
            activeStep={activeStep}
            handleBack={handleBack}
            handleNext={handleNext}
            steps={steps}
          />
        );
      case 3:
        return (
          <Step4
            activeStep={activeStep}
            handleBack={handleBack}
            handleNext={handleNext}
            steps={steps}
            formik={formik}
            handleSubmitForm={handleSubmitForm}
            submitError={submitError}
            setSubmitError={setSubmitError}
          />
        );
      default:
        return (
          <Step1
            activeStep={activeStep}
            handleBack={handleBack}
            handleNext={handleNext}
            steps={steps}
          />
        );
    }
  }
  
  const formik = useFormik({
    initialValues: {
      company_name: "",
      account_address_1: "",
      account_country_name: "",
      account_region_name: "",
      account_city_name: "",
      account_postal_code: "",
    },
    validationSchema: FormSchema,
  });

  const handleSubmitForm = (output) => {
    let postData = {};
    postData = jsonConcat(postData, userData);
    postData = jsonConcat(postData, output);

    // submit
    // const api_save_key = process.env.REACT_APP_API_URL_SAVE;
    const apiSaveUrl =
      process.env.NODE_ENV === "production"
        ? "https://cliql.dmsgroup.com/publisher/signup/save"
        : "https://cliql.dmsgroup.com/publisher/signup/save";
    axios({
      method: "post",
      url: apiSaveUrl,
      data: postData,
    }).catch(function (error) {
      setSubmitError(error);
    }).then(function (response) {
      if (response) {
        localStorage.removeItem("formValues");
        localStorage.removeItem("signup-form");
        localStorage.removeItem("signup-form-step-2");
        localStorage.removeItem("signup-form-step-3");
        localStorage.removeItem("signup-form-step-4");
        if (response.errors) {
          navigate("/error");
        } else {
          navigate("/thankyou");
        }
      }
    });
  };

  const FormSchema = Yup.object().shape({
    company_name: Yup.string().required("Company name required"),
    account_address_1: Yup.string().required("Address required"),
    account_country_name: Yup.string().required("Country is required"),
    account_region_name: Yup.string().required("Region is required"),
    account_city_name: Yup.string().required("City is required"),
    account_postal_code: Yup.string().required("Postal code is required"),
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    isSubmitting,
    validateForm,
  } = formik;

  return (
    <Box sx={{ maxWidth: "760px", m: "auto", w: "100%", pl: "1em", pr: "1em" }}>
      {response &&
        <div>
          <img src={mainLogo} className="logo" alt="DMS Performance Ad Market" />
          <h2>Publisher Application</h2>
          <Stepper activeStep={activeStep} sx={{ mt: 4, mb: 4 }} alternativeLabel>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <FormikProvider value={formik} innerRef={ref} enableReinitialize>
            {activeStep === steps.length ? (
              <div>
                All steps completed
                <Button>Reset</Button>
              </div>
            ) : (
              <div>
                {/* <CSSTransition
                  in={isSelected}
                  timeout={{
                    appear: 300,
                    enter: 300,
                    exit: 100,
                  }}
                  classNames="icon"
                  onExited={() => setIsSelected(!isSelected)}
                > */}
                {getStepContent(activeStep)}
                {/* </CSSTransition> */}
              </div>
            )}
          </FormikProvider>
        </div>
      }
    </Box>
  );
});

export default SignUpForm;

