import React from "react";
import Container from "@mui/material/Container";
import { Box } from "@mui/material";
import mainLogo from "../assets/images/DMS-PAM-logo.png";
import "../app.scss";

const Error = () => {
  return (
    <Container disableGutters>
      <Box sx={{ maxWidth: "700px", m: "4em auto 1em" }}>
        <img src={mainLogo} id="error-logo" className="logo" alt="DMS Performance Ad Market" />
        <h1>
          We encountered an error. Your registration form may have expired. Please <a href="https://applynow.dmsgroup.com/">sign up again</a>.
        </h1>
      </Box>
    </Container>
  );
};

export default Error;
