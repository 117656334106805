import React from "react";
import Container from "@mui/material/Container";
import { Box } from "@mui/material";
import mainLogo from "../assets/images/DMS-PAM-logo.png";
import "../app.scss";

const ThankYou = () => {
  return (
    <Container disableGutters>
      <Box sx={{ maxWidth: "700px", m: "4em auto 1em", p: "1em" }}>
        <img src={mainLogo} className="logo" alt="DMS Performance Ad Market" />
        <h1>Your application has been submitted.</h1>

        <h3>
          Be on the lookout for an email detailing next steps and what to expect. We will review your application as soon as possible!
        </h3>
      </Box>
    </Container>
  );
};

export default ThankYou;
