import React from "react";
import Container from "@mui/material/Container";
import SignUpForm from "../components/SignUpForm";
// import mainLogo from "../assets/images/DMS-PAM-logo.png";
import "../app.scss";

const Form = React.memo(() => {
  return (
    <Container disableGutters>
      <div className={"container mx-auto px-4"}>
        <SignUpForm />
      </div>
    </Container>
  );
});

export default Form;
