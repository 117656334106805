import * as React from "react";
import * as Yup from "yup";
import { ref } from "yup";
import { useFormik, Form, FormikProvider, Formik } from "formik";
import { PersistFormikValues } from "formik-persist-values";
import Container from "@mui/material/Container";
import { jsonConcat } from "./../helper/helper";
import {
  Box,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
  FormHelperText,
} from "@mui/material";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const Step2 = ({ activeStep, handleBack, handleNext, steps }) => {
  const [phoneNumber, setPhoneNumber] = React.useState();
  const [phoneTime, setPhoneTime] = React.useState([]);
  const countryCode = localStorage.getItem('country_code');

  const handleChangePhone = (event) => {
    const {
      target: { value },
    } = event;
    setPhoneTime(typeof value === "string" ? value.split(",") : value);
  };

  // remove validation for phone on keyup
  React.useEffect(() => {
    const phoneInput = document.querySelector('input[name="phone-number"]');
    
    phoneInput.addEventListener("keyup", (e) => {
      document.getElementById("phone-required").style.display = "none";
      document.getElementById("phone-number").classList.remove("error");
    });
  }, []);

  // validate
  const Step2Schema = Yup.object().shape({
    contact_start_time: Yup.string().required("This field is required"),
    messenger_type: Yup.string().required("This field is required"),
    messenger_name: Yup.string().required("This field is required"),
    other_messenger_type: Yup.string().when('messenger_type', {
      is: "3",
      then: Yup.string().required("This field is required"),
      otherwise: Yup.string(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      primary_phone: "",
      contact_start_time: "",
      messenger_type: "",
      other_messenger_type: "",
      messenger_name: "",
      linkedin_url: "",
    },
    validationSchema: Step2Schema,
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    validateForm,
    setFieldValue,
  } = formik;

  return (
    <Container disableGutters>
      <FormikProvider value={formik} enableReinitialize>
        <div>
          <Box autoComplete="off" alignItems="center" justifyContent="center">
            <PhoneInput
              placeholder="Enter phone number (including country code) *"
              value={phoneNumber}
              onChange={setPhoneNumber}
              name="phone-number"
              id="phone-number"
              defaultCountry={countryCode}
            />
            <p id="phone-required">Phone Number Required</p>
            <FormControl sx={{ width: "100%", marginTop: "1.5em" }}>
              <InputLabel id="phone-contact-label">
                Best time to contact you by phone *
              </InputLabel>
              <Select
                required
                labelId="phone-contact-label"
                id="phone-contact"
                name="contact_start_time"
                value={phoneTime}
                label="Best time to contact you by phone"
                onChange={handleChangePhone}
                {...getFieldProps("contact_start_time")}
                error={Boolean(
                  touched.contact_start_time && errors.contact_start_time
                )}
              >
                <MenuItem value="9:00AM-11:00AM PST">9:00AM-11:00AM PST</MenuItem>
                <MenuItem value="11:00AM-1:00PM PST">11:00AM-1:00PM PST</MenuItem>
                <MenuItem value="1:00PM-3:00PM PST">1:00PM-3:00PM PST</MenuItem>
                <MenuItem value="3:00PM-5:00PM PST">3:00PM-5:00PM PST</MenuItem>
              </Select>
              <FormHelperText>
                {touched.contact_start_time && errors.contact_start_time}
              </FormHelperText>
            </FormControl>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="im-service-label">IM Service *</InputLabel>
              <Select
                required
                labelId="im-service-label"
                id="im-service"
                name="messenger_type"
                // value={formik.values.messenger_type}
                label="IM Service *"
                {...getFieldProps("messenger_type")}
                error={Boolean(touched.messenger_type && errors.messenger_type)}
              >
                <MenuItem value="2">Skype</MenuItem>
                <MenuItem value="10">Facebook Messenger</MenuItem>
                <MenuItem value="11">Google Chat</MenuItem>
                <MenuItem value="12">LINE</MenuItem>
                <MenuItem value="13">Slack</MenuItem>
                <MenuItem value="14">Telegram</MenuItem>
                <MenuItem value="15">Signal</MenuItem>
                <MenuItem value="16">Viber</MenuItem>
                <MenuItem value="17">WeChat</MenuItem>
                <MenuItem value="18">WhatsApp</MenuItem>
                <MenuItem value="3">Other</MenuItem>
              </Select>
              <FormHelperText>
                {touched.messenger_type && errors.messenger_type}
              </FormHelperText>
            </FormControl>
            {formik.values.messenger_type &&
              formik.values.messenger_type == "3" && (
                <TextField
                  required
                  fullWidth
                  label="Other IM Service"
                  name="other_messenger_type"
                  variant="outlined"
                  align="center"
                  {...getFieldProps("other_messenger_type")}
                  error={Boolean(touched.other_messenger_type && errors.other_messenger_type)}
                  helperText={touched.other_messenger_type && errors.other_messenger_type}
                />
              )}
            <TextField
              required
              fullWidth
              label="IM Screenname"
              name="messenger_name"
              variant="outlined"
              align="center"
              {...getFieldProps("messenger_name")}
              error={Boolean(touched.messenger_name && errors.messenger_name)}
              helperText={touched.messenger_name && errors.messenger_name}
              inputProps={{ maxLength: 250 }}
            />
            <TextField
              fullWidth
              label="LinkedIn URL"
              name="linkedin_url"
              variant="outlined"
              align="center"
              {...getFieldProps("linkedin_url")}
              inputProps={{ maxLength: 255 }}
            />
          </Box>
          <div className="navigate">
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1, p: "1em 3em" }}
                variant="contained"
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                onClick={() => {
                  // handleSubmit function is to force validation- it will give an error in the console but ignore it
                  handleSubmit();
                  validateForm().then((errors) => {
                    const isEmpty = (obj) => {
                      for (let key in obj) {
                        if (obj.hasOwnProperty(key)) return false;
                      }
                      return true;
                    };

                    const pass = isEmpty(errors);
                    if (pass === false) {
                      console.log(errors);
                    } else {
                      // parse local storage string into array
                      const formValuesArray = JSON.parse(
                        localStorage.getItem("formValues")
                      );

                      // turn formik values into array
                      const values = formik.values;
                      const formikValues = JSON.stringify(values);
                      let backToArray = JSON.parse(formikValues);

                      // manually add phone number
                      const phoneNumber =
                        document.getElementById("phone-number").value;

                      const phoneArray = {
                        primary_phone: phoneNumber,
                      };

                      // put it all together
                      let output = {};
                      output = jsonConcat(output, formValuesArray);
                      output = jsonConcat(output, backToArray);
                      output = jsonConcat(output, phoneArray);

                      // set new array in local storage
                      localStorage.setItem(
                        "formValues",
                        JSON.stringify(output)
                      );

                      // validate phone manually because they are not mui
                      if (phoneNumber === "") {
                        document.getElementById(
                          "phone-required"
                        ).style.display = "block";
                        document
                          .getElementById("phone-number")
                          .classList.add("error");
                      } else {
                        // next page
                        handleNext();
                      }
                    }
                  });
                }}
                variant="contained"
                sx={{ mr: 1, p: "1em 3em" }}
                type={activeStep === steps.length - 1 ? "submit" : "button"}
              >
                {activeStep === steps.length - 1 ? "Submit" : "Next"}
              </Button>
            </Box>
          </div>
        </div>
        <PersistFormikValues name="signup-form-step-2" />
      </FormikProvider>
    </Container>
  );
};

export default Step2;
