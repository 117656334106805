import React from "react";
import Container from "@mui/material/Container";
import Register from "../components/Register";
import { Box } from "@mui/material";
import mainLogo from "../assets/images/DMS-PAM-logo.png";
import "../app.scss";

const Home = () => {
  return (
    <Container disableGutters sx={{ marginBottom: "2em" }}>
      <Box sx={{ width: "100%", maxWidth: "700px", marginLeft: "auto", marginRight: "auto", marginTop: "4em", marginBottom: "2em", pb: "2em", pl: "1em", pr: "1em" }}>
        <img
          src={mainLogo}
          className="logo"
          alt="DMS Performance Ad Market"
        />
        <h3 className="title">Publisher Application</h3>
        <Register />
      </Box>
    </Container>
  );
};

export default Home;
