import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    // type: "light",
    primary: {
      main: "#0082ca",
      // secondary: "#ffffff",
      // light: "#ffffff",
      // dark: "#467ca2",
    },
    secondary: {
      main: "#ffffff",
    },
    error: {
      main: "#cb4641",
    },
  },
  typography: {
    // fontFamily: "Nunito Sans",
    fontSize: 16,
  },
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
    },
    // MuiSelect: {
    //   styleOverrides: {
    //     select: {
    //       backgroundColor: "#000",
    //       color: "#fff",
    //     },
    //   },
    // },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          // top: "calc(50% - 28px)",
          left: "0.6em",
          backgroundColor: "#ffffff",
          paddingRight: "14px",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "#cb4641",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: "1.5em !important",
        },
      },
    },
    // MuiOutlinedInput: {
    // notchedOutline: {
    //   borderColor: "red",
    // },
    //   styleOverrides: {
    //     input: {
    //       padding: "1em",
    //     },
    // select: {
    //   backgroundColor: "#000",
    //   color: "#fff",
    // },
    // },
    // },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          padding: "20px",
        },
        sizeSmall: {
          padding: "6px 16px",
        },
        sizeMedium: {
          padding: "8px 20px",
        },
        sizeLarge: {
          padding: "11px 24px",
        },
        textSizeSmall: {
          padding: "7px 12px",
        },
        textSizeMedium: {
          padding: "9px 16px",
        },
        textSizeLarge: {
          padding: "12px 16px",
        },
      },
    },
    MuiContainer: {
      root: {
        padding: "0",
      },
    },
  },
  // overrides: {
  //   MuiInput: {
  //     // Name of the component ⚛️ / style sheet
  //     root: {
  //       // Name of the rule
  //       color: "red", // Some CSS
  //     },
  //   },
  // },
});