import * as React from "react";
import * as Yup from "yup";
import { ref } from "yup";
import { useFormik, Form, FormikProvider, Formik } from "formik";
import { PersistFormikValues } from "formik-persist-values";
import Container from "@mui/material/Container";
import { jsonConcat } from "./../helper/helper";
import {
  Box,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button
} from "@mui/material";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";

const Step1 = ({activeStep, handleBack, handleNext, steps}) => {
  const [country, setCountry] = React.useState([]);
  const [region, setRegion] = React.useState([]);
  const [userData, setUserData] = React.useState([]);
  const [channels, setChannels] = React.useState([]);

  const handleSelectCountry = (event) => {
    setCountry(event);
    const countryData = CountryRegionData.find((obj) => obj[0] == event);
    const chosenCountry = countryData[1];
    localStorage.setItem("country_code", chosenCountry);
    document.getElementById("country-required").style.display = "none";
    document.getElementById("country").classList.remove("error");
  };

  const handleSelectRegion = (event) => {
    setRegion(event);
    document.getElementById("region-required").style.display = "none";
    document.getElementById("region").classList.remove("error");
  };

  // validate
  const Step1Schema = Yup.object().shape({
    company_name: Yup.string().required("Company name required"),
    account_address_1: Yup.string().required("Address required"),
    // account_country_name: Yup.string().required("Country is required"),
    // account_region_name: Yup.string().required("Region is required"),
    account_city_name: Yup.string().required("City is required"),
    account_postal_code: Yup.string().required("Postal code is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      company_name: "",
      account_address_1: "",
      account_address_2: "",
      account_country_name: "",
      account_region_name: "",
      account_city_name: "",
      account_postal_code: "",
      title: "",
      website_url: "",
    },
    validationSchema: Step1Schema,
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    validateForm,
  } = formik;

  return (
    <Container disableGutters>
      <FormikProvider
        value={formik}
        validationSchema={Step1Schema}
        initialValues={formik.initialValues}
        enableReinitialize
      >
        <div>
          <Box
            //component="form"
            autoComplete="off"
            alignItems="center"
            justifyContent="center"
          >
            <TextField
              fullWidth
              required
              label="Company Name"
              name="company_name"
              variant="outlined"
              align="center"
              {...getFieldProps("company_name")}
              error={Boolean(touched.company_name && errors.company_name)}
              helperText={touched.company_name && errors.company_name}
              inputProps={{ maxLength: 250 }}
            />
            <TextField
              fullWidth
              label="Title / Function in Organization"
              name="title"
              variant="outlined"
              align="center"
              inputProps={{ maxLength: 20 }}
              {...getFieldProps("title")}
            />
            <TextField
              fullWidth
              label="Website"
              name="website_url"
              variant="outlined"
              align="center"
              value={formik.initialValues.website_url ?? ""}
              {...getFieldProps("website_url")}
              inputProps={{ maxLength: 250 }}
            />
            <TextField
              fullWidth
              required
              label="Street Address"
              name="account_address_1"
              variant="outlined"
              align="center"
              {...getFieldProps("account_address_1")}
              error={Boolean(
                touched.account_address_1 && errors.account_address_1
              )}
              helperText={touched.account_address_1 && errors.account_address_1}
              inputProps={{ maxLength: 250 }}
            />
            <TextField
              fullWidth
              label="Apt / Suite / Other"
              name="account_address_2"
              variant="outlined"
              align="center"
              {...getFieldProps("account_address_2")}
              inputProps={{ maxLength: 250 }}
            />
            <Box className="country-region-box">
              <Box sx={{ width: "49%" }}>
                <CountryDropdown
                  id="country"
                  name="account_country_name"
                  value={country.toString()}
                  onChange={(val) => handleSelectCountry(val)}
                  className="minimal"
                  priorityOptions={["US"]}
                  defaultOptionLabel="Select Country *"
                  required
                />
                <p id="country-required">Country Required</p>
              </Box>
              <Box sx={{ width: "49%" }}>
                <RegionDropdown
                  id="region"
                  name="account_region_name"
                  country={country.toString()}
                  value={region.toString()}
                  onChange={(val) => handleSelectRegion(val)}
                  className="minimal"
                  blankOptionLabel="Select Region *"
                  required
                />
                <p id="region-required">Region Required</p>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <TextField
              fullWidth
              required
              label="City"
              name="account_city_name"
              variant="outlined"
              align="center"
              sx={{ width: "49%", marginTop: "1.5em" }}
              {...getFieldProps("account_city_name")}
              error={Boolean(
                touched.account_city_name && errors.account_city_name
              )}
              helperText={touched.account_city_name && errors.account_city_name}
              inputProps={{ maxLength: 250 }}
            />
            <TextField
              fullWidth
              required
              label="Postal/Zip Code"
              name="account_postal_code"
              variant="outlined"
              align="center"
              inputProps={{ maxLength: 16 }}
              sx={{ width: "49%", marginTop: "1.5em" }}
              {...getFieldProps("account_postal_code")}
              error={Boolean(
                touched.account_postal_code && errors.account_postal_code
              )}
              helperText={
                touched.account_postal_code && errors.account_postal_code
              }
            />
          </Box>
          <div className="navigate">
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1, p: "1em 3em" }}
                variant="contained"
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                onClick={() => {
                  // handleSubmit function is to force validation- it will give an error in the console but ignore it
                  handleSubmit();
                  validateForm().then((errors) => {
                    console.log(errors);
                    const isEmpty = (obj) => {
                      for (let key in obj) {
                        if (obj.hasOwnProperty(key)) return false;
                      }
                      return true;
                    };

                    const pass = isEmpty(errors);
                    if (pass === false) {
                      console.log(errors);
                    } else {
                      // set initial formik values into JSON string into local storage
                      localStorage.setItem(
                        "formValues",
                        JSON.stringify(formik.values)
                      );

                      // add country and region manually because they are not formik components
                      const country = document.getElementById("country").value;
                      const region = document.getElementById("region").value;

                      const countryRegion = {
                        account_country_name: country,
                        account_region_name: region,
                      };

                      let output = {};
                      output = jsonConcat(output, formik.values);
                      output = jsonConcat(output, countryRegion);

                      // set output into local storage
                      localStorage.setItem(
                        "formValues",
                        JSON.stringify(output)
                      );

                      // validate country and region manually because they are not mui
                      if (country === "" || region === "") {
                        if (country === "") {
                          document.getElementById(
                            "country-required"
                          ).style.display = "block";
                          document
                            .getElementById("country")
                            .classList.add("error");
                        } else {
                          document.getElementById(
                            "country-required"
                          ).style.display = "none";
                          document
                            .getElementById("country")
                            .classList.remove("error");
                        }

                        if (region === "") {
                          document.getElementById(
                            "region-required"
                          ).style.display = "block";
                          document
                            .getElementById("region")
                            .classList.add("error");
                        } else {
                          document.getElementById(
                            "region-required"
                          ).style.display = "none";
                          document
                            .getElementById("region")
                            .classList.remove("error");
                        }
                      } else {
                        // next page
                        handleNext();
                      }
                    }
                  });
                }}
                variant="contained"
                sx={{ mr: 1, p: "1em 3em" }}
                type={activeStep === steps.length - 1 ? "submit" : "button"}
              >
                {activeStep === steps.length - 1 ? "Submit" : "Next"}
              </Button>
            </Box>
          </div>
        </div>
        <PersistFormikValues name="signup-form" />
      </FormikProvider>
    </Container>
  );
};

export default Step1;
